import React from 'react';
import './LandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from './logo.png'; // Ensure your logo.png is in the same directory

const LandingPage = () => {
  return (
    <div className="container">
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Althical</h1>
        <p className="tagline">Democratizing Medical AI.</p>
      </header>

      <main className="main">
        <div className="button-group">
          <button
            className="waiting-list-button"
            onClick={() => window.open('https://forms.gle/SCvoujx1H6frVfnH9', '_blank')}
          >
            Join Waiting List
          </button>
          <button
            className="calendly-button"
            onClick={() => window.open('https://calendly.com/marjiasdk/althical', '_blank')}
          >
            Book a Meeting
          </button>
        </div>
      </main>

      <div className="icon-container">
        <div className="social-links">
          <a href="https://linkedin.com/company/althical" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="mailto:contact@althical.com">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
        <p className="copyright">© 2024 Althical</p>
      </div>
    </div>
  );
};

export default LandingPage;
