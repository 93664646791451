import LandingPage from './components/LandingPage.js'

function App() {
  return (
    <div className="App">
        <LandingPage />
    </div>
  );
}

export default App;
